<template>
    <Breadcrumb page-title="Operations Escalation Form " page-section="Custom Forms" page-des="Use this form to submit escalation requests for orders."> </Breadcrumb>
    <section class="content">
        <div class="row">
            <div class="col-md-12">
                <div class="box box-info">
                    <div class="box-body">
                        <div class="row">
                        <div class="col-md-8 form-horizontal">
                            <div class="form-group">
                                <label class="col-sm-8 control-label text-left"></label>
                            </div>
                            <div class="form-group">
                                <label class="col-sm-4 control-label text-left">Customer/Biller Name<span class="required">(Required)</span></label>
                                <div class="col-sm-6">
                                    <input type="text" value="" class="form-control">
                                </div>
                            </div>
                            <div class="form-group">
                                <label class="col-sm-4 control-label text-left">Order <span class="required">(Required)</span></label>
                                <div class="col-sm-6">
                                    <input type="text" value="" class="form-control">
                                </div>
                            </div>
                            <div class="form-group">
                                <label class="col-sm-4 control-label text-left">Reason for Escalation <span class="required">(Required)</span></label>
                                <div class="col-sm-6">
                                    <select class="form-control">
                                        <option value="">Canada - Loaded - Portal Entry Needed</option>
                                        <option value="">Canada - Empty into US - Menifest Needed</option>
                                        <option value="">Canada - Empty into Canada - Menifest Needed</option>
                                        <option value="">Ccustomer Follow Up Needed</option>
                                        <option value="">Delivery Confirmation/Delivery Issues</option>
                                        <option value="">Order Entry Escalation</option>
                                        <option value="">Other (specfied in comments)</option>
                                        <option value="">Paperwork Request</option>
                                        <option value="">Pickup or AWB# needed/Pickup Issues</option>
                                        <option value="">TSA Order Entry</option>
                                    </select>
                                </div>
                            </div>
                            
                            <div class="form-group">
                                <label class="col-sm-4 control-label text-left">If requesting paperwork, confirm you checked E:/Public/Shipment Paperwork for Pickup
                                </label>
                                <div class="col-sm-6">
                                    <select class="form-control">
                                        <option value="Low">Yes</option>
                                        <option value="Medium">No</option>
                                    </select>
                                </div>
                            </div>
                            <div class="form-group">
                                <label class="col-sm-4 control-label text-left">Team <span class="required">(Required)</span></label>
                                <div class="col-sm-6">
                                    <select class="form-control">
                                        <option value="">Select Team</option>
                                        <option value="Art Team">Art Team</option>
                                        <option value="Medium">MediCultivate Sales</option>
                                        <option value="High">Dedicated Team</option>
                                        <option value="High">ESS Customer Solutions</option>
                                        <option value="High">Hazmat Team</option>
                                        <option value="High">Mexico Team</option>
                                        <option value="High">Portal Issues Team</option>
                                        <option value="High"> Rescue Team</option>
                                        <option value="High"> TSA Team</option>
                                    </select>
                                </div>
                            </div>
                            <div class="form-group">
                                <label class="col-sm-4 control-label text-left">Attempted one call to resolve the issue before escalating <span class="required">(Required)</span>
                                </label>
                                <div class="col-sm-6">
                                    <select class="form-control">
                                        <option value="Low">Yes</option>
                                        <option value="Medium">No</option>
                                    </select>
                                </div>
                            </div>
                            <div class="form-group">
                                <label class="col-sm-4 control-label text-left">Comments
                                </label>
                                <div class="col-sm-6">
                                    <textarea class="form-control"></textarea>
                                </div>
                            </div>
                            <div class="form-group">
                                <label class="col-sm-4 control-label text-left">&nbsp;</label>
                                <div class="col-md-6">
                                    <button type="button" class="btn btn-primary margin-r-5">Submit Escalation</button>
                                </div>
                            </div>
                        </div>
                        </div>
                    </div>
                </div>
            </div>
            </div>

    </section>
 </template>
 <style src="@vueform/toggle/themes/default.css"></style>
 <script>
 // @ is an alias to /src
 import Breadcrumb from '../components/Breadcrumb.vue';
 import Toggle from '@vueform/toggle'
 export default {
   name: 'New Account',
   components: {
     Breadcrumb,
     Toggle,
   },
   data: function(){
       return{
        
       }
          
   },
   ready () {
     $(document).ready(function ($) {
     })
   },
    methods: {
     toggleSegmentCollapsedState(i) {
       this['segment'+i] = !this['segment'+i] 
     }
   }
 }
 </script>
 